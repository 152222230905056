import React from "react";

function Banner_top() {
  return (
    <div>
      <div className="section-intro"></div>
      <div className="banner-top">
        <div className="banner-content text-center">
          <h1>BẠN ĐÃ SẲN SÀNG ĐỂ BẮT ĐẦU CUỘC HÀNH TRÌNH !</h1>
          <hr />
          <p>
            Tự tay cầm lái chiếc xe bạn yêu thích cho hành trình thêm hứng khởi
          </p>
          <a href="#" className="btn btn-success">
            Thuê xe ngay
          </a>
        </div>
      </div>
    </div>
  );
}

export default Banner_top;
