import React from "react";

export default function Endow() {
  return (
    <div>
      <div className="section-intro"></div>
      <div className="container">
        <div className="title mb-4">
          <h1 className="text-center">ƯU ĐIỂM NỔI BẬT</h1>
          <p className="text-center">
            <span>Nhận nhiều ưu đãi khuyến mãi từ ADL TRIPLE T</span>
          </p>
        </div>
        <div className="row">
          <div className="col-md-4">
            <div className=" text-center p-4 mb-4">
              <img src="/upload/uudiem 1.svg" />

              <h5>Dòng xe đa dạng</h5>
              <p>
                Hơn 100 dòng xe cho bạn tuỳ ý lựa chọn: Mini, Sedan, CUV, SUV,
                MPV, Bán tải.
              </p>
            </div>
          </div>
          <div className="col-md-4">
            <div className="text-center p-4 mb-4">
              <img src="/upload/uudiem 2.svg" />

              <h5>Thủ tục đơn giản</h5>
              <p>Chỉ cần vài bước đơn giản để hoàn tất thủ tục thuê xe.</p>
            </div>
          </div>
          <div className="col-md-4">
            <div className=" text-center p-4 mb-4">
              <img src="/upload/uudiem 3.svg" />

              <h5>An toàn lái xe</h5>
              <p>
                Các dòng xe được kiểm tra kỹ lưỡng để đảm bảo an toàn cho khách
                hàng.
              </p>
            </div>
          </div>
          <div className="col-md-4">
            <div className=" text-center p-4 mb-4">
              <img src="/upload/uudiem 4.svg" />

              <h5>Thanh toán dễ dàng</h5>
              <p>
                Đa dạng hình thức thanh toán: ATM, thẻ Visa & Ví điện tử (Momo,
                VnPay, ZaloPay).
              </p>
            </div>
          </div>{" "}
          <div className="col-md-4">
            <div className="text-center p-4 mb-4">
              <img src="/upload/uudiem 5.svg" />

              <h5>Giao xe tận nơi</h5>
              <p>
                Bạn có thể lựa chọn giao xe tận nhà/sân bay... Phí tiết kiệm chỉ
                từ 15k/km.
              </p>
            </div>
          </div>{" "}
          <div className="col-md-4">
            <div className=" text-center p-4 mb-4">
              <img src="/upload/uudiem 6.svg" />

              <h5>Dòng xe đa dạng</h5>
              <p>
                Hơn 100 dòng xe cho bạn tuỳ ý lựa chọn: Mini, Sedan, CUV, SUV,
                MPV, Bán tải.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
