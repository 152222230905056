import React from "react";

const Loading = () => {
  return (
    <div className="loading-overlay">
      {" "}
      <img src="../upload/gif car.gif" alt="Loading..." />{" "}
    </div>
  );
};

export default Loading;
